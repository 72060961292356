























import Vue from 'vue';
import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ModelSelect } from 'vue-search-select';

import { ACL } from '@/modules/acl/acl-service';
import { ExportService } from '@/services/export-service';
import { TrashAreaAvailability } from '@/services/api/report/trash-area/trash-area-availability';
import { TRASH_AREA_AVAILABILITY_OPTIONS } from '@/constants/Global';

const municipalitiesHelpers = createNamespacedHelpers('municipalities');

export default defineComponent({
  name: 'trash-area-form3',
  components: {
    ModelSelect,
  },
  setup(props, { root }) {
    const { current, isCurrentGeneral } = municipalitiesHelpers.useGetters(['current', 'isCurrentGeneral']);

    const filter: {
      availability: {
        value?: number,
        text?: string,
      },
    } = reactive({
      availability: {},
    });

    const availabilityOptions = computed(() => ([
      {
        text: 'Обеспеченность',
      },
      ...TRASH_AREA_AVAILABILITY_OPTIONS.map(option => ({
        value: option.id,
        text: option.name,
      })),
    ]));

    const canExport = ACL.can('report_trash_area_export.form3');

    const init = () => {
      const municipalityId = current.value ? current.value.id : 0;
    };
    init();
    watch(current, () => init());

    return {
      filter,
      current,
      isCurrentGeneral,
      canExport,
      availabilityOptions,
      exportReport() {
        TrashAreaAvailability.exportReport({
          municipalityId: current.value ? current.value.id : 0,
          availability: filter.availability.value ? filter.availability.value : 0,
          availabilityText: filter.availability.text ? filter.availability.text : '',
        })
          .then((file: Blob) => {
            ExportService.downloadFile(file, ExportService.VND_EXCEL_FILE_TYPE, 'Форма 3.xlsx');
          })
          .catch(() => {});
      },
    };
  },
});
