import axios from '@/services/axios';
import { AxiosResponse } from 'axios';

export class TrashAreaAvailability {
  static exportReport({
    municipalityId,
    availability,
    availabilityText,
  }: {
    municipalityId?: number,
    availability?: number,
    availabilityText?: string,
  }): Promise<Blob> {
    const params: {
      // eslint-disable-next-line camelcase
      municipality_id?: number,
      availability?: number,
    } = {};
    if (municipalityId) {
      params.municipality_id = municipalityId;
    }
    if (availability) {
      params.availability = availability;
    }
    return axios.get('/report-trash-area/export/form3', { params, responseType: 'blob' })
      .then((response: AxiosResponse<Blob>) => {
        return response.data;
      });
  }
}
